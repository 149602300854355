import React from "react";
import "./src/styles/global.css";

export const wrapPageElement = ({ element, props }) => {
  const Layout = element.type.Layout;
  if (Layout) {
    return <Layout {...props}>{element}</Layout>;
  } else {
    return <>{element}</>;
  }
};
